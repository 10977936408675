<template>
  <div class="settings-wrapper py-md-5">
    <div :class="{ white: !isDesktop }" class="d-flex align-center py-4 py-md-0">
      <router-link
        v-if="!isDesktop"
        to="/"
        class="back-link px-2"
      >
        <v-icon size="24px" color="#6170C5">
          navigate_before
        </v-icon>
      </router-link>
      <span
        :class="isDesktop ? 'fz-29 font-weight-semi-bold line-h-32' : 'fz-25 font-weight-bold line-h-29'"
        class="black-text pb-md-5 px-md-0"
      >
        Settings
      </span>
    </div>

    <v-card
      style="box-shadow: unset;"
      color="transparent"
      class="px-3"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="py-6"
        >
          <v-row>
            <v-col
              :class="{ white: !isDesktop }"
              cols="12"
              md="3"
              class="px-4 px-md-0"
            >
              <h2 :class="isDesktop ? 'font-weight-semi-bold' : 'font-weight-medium'" class="fz-16">Subdomain</h2>
            </v-col>
            <!-- Hide this block for NOW -- FONIO-1192 -->
            <!-- Close Account" Button missing in settings page -- FONIO-1708 -->
            <v-col
              cols="12"
              md="9"
              class="px-0 py-0 d-flex justify-md-end align-start"
            >
              <v-card
                class="pa-5"
                :class="isDesktop ? 'max-width-desktop' : 'max-width-mobile'"
                width="100%"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    class="fz-14 d-flex align-center"
                  >
                    <v-text-field
                      v-model="subdomain"
                      :disabled="!!companyData.web_url"
                      class="subdomain-text"
                      solo
                      outlined
                      type="text"
                      dense
                      hide-details
                      @keypress="onlyLetters"
                    >
                      <template v-if="!companyData.web_url" v-slot:append>
                        {{ domain }}
                      </template>
                    </v-text-field>
                    <v-btn
                      v-if="!companyData.web_url"
                      :disabled="!subdomain"
                      text
                      class="text-capitalize fz-16 font-weight-medium primary ml-2 save-subdomain"
                      @click="saveSubdomain"
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="isDesktop"
          cols="12"
          md="12"
          class="px-0 mb-2"
        >
          <v-divider></v-divider>
        </v-col>
        <v-col
          :class="{ white: !isDesktop }"
          cols="12"
          md="3"
          class="pl-0 mt-4 pb-0 d-md-block d-flex align-center justify-space-between pb-4 pb-md-0"
        >
          <h2
            :class="isDesktop ? 'font-weight-semi-bold' : 'font-weight-medium'"
            class="fz-16 px-4 px-md-0 pb-0 black--text"
          >Payments</h2>
          <v-btn
            v-if="!isDesktop"
            text
            color="#6170C5"
            class="text-capitalize fz-16 font-weight-medium"
            @click="addPaymentMethod"
          >
            Add payment
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          md="9"
          class="px-0 pt-0 pb-0 mb-4 d-flex justify-md-end align-start"
        >
          <v-card
            class="pa-5 pt-0 pt-md-5 overflow-hidden"
            :class="isDesktop ? 'max-width-desktop' : 'max-width-mobile'"
            width="100%"
          >
            <v-row>
              <template>
                <v-col
                  v-if="isDesktop && hasLastPayment"
                  cols="12"
                  md="6"
                  class="pt-3 pb-2 fz-14 d-flex align-center"
                >
                  <div class="d-flex flex-column">
                    <span class="fz-16 font-weight-medium">Last payment</span>
                    <span class="no-wrap" style="color: #637381">${{ sum }} on {{ date }}</span>
                  </div>
                </v-col>
                <v-col
                  v-if="isDesktop && hasLastPayment"
                  cols="12"
                  md="6"
                  class="pt-3 pb-2 text-right"
                >
                  <v-btn
                    to="/payments-history"
                    class="grey-button"
                  >
                    View payments
                  </v-btn>
                </v-col>
              </template>

              <template>
                <v-list
                  v-for="(card, index) in cardList"
                  :key="index"
                  width="100%"
                  class="py-0"
                >
                  <v-col
                    cols="12"
                    md="12"
                    class="px-0"
                  >
                    <v-divider
                      class="hl-724"
                     ></v-divider>
                  </v-col>

                  <v-list-item>
                    <v-list-item-content class="py-2">
                      <v-list-item-title class="d-flex align-center flex-wrap">
                        <img src="@/assets/card-icon.svg" alt="card" class="mr-3 my-1">
                        <span :class="{ 'velvet-grey' : !isDesktop }" class="fz-14 mr-4 my-1">**** **** **** {{ card.card.last4 }}</span>
                        <v-chip
                          v-if="card.isDefault"
                          class="fz-12 py-1 px-2 my-1"
                          color="#B3BCF5"
                          small
                        >
                          Primary
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action
                      class="py-2 text-right d-flex flex-row ma-0"
                    >
                      <v-tooltip v-if="!card.isDefault" top content-class="v-tooltip--top">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="textButton pa-2 ml-2"
                            min-width="36px"
                            v-bind="attrs"
                            v-on="on"
                            @click="setDefault(card.id)"
                          >
                            <v-icon color="#919EAB">check_circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Set Default</span>
                      </v-tooltip>
                      <v-tooltip top content-class="v-tooltip--top">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="textButton pa-2 ml-2"
                            min-width="36px"
                            v-bind="attrs"
                            v-on="on"
                            @click="editPaymentMethod(card.id)"
                          >
                            <v-icon color="#919EAB">edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Remove</span>
                      </v-tooltip>

                      <v-tooltip top content-class="v-tooltip--top">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="textButton pa-2 ml-2"
                            min-width="36px"
                            v-bind="attrs"
                            v-on="on"
                            @click="openRemoveDialog(card.id)"
                          >
                            <v-icon color="#919EAB">delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Remove</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </template>
              <v-col
                v-if="Role.CompanyAdmin && isDesktop"
                cols="12"
                md="12"
              >
                <v-divider v-if="hasLastPayment" class="hl-724" />
                <v-row>
                  <v-col
                    cols="12"
                    mb="12"
                    :class="hasLastPayment ? 'pt-8' : 'pt-3'"
                  >
                    <v-btn
                      color="#6170C5"
                      class="text-capitalize fz-14 font-weight-medium white--text"
                      @click="addPaymentMethod"
                    >
                      Add payment method
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <template v-if="Role.CompanyAdmin">
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-divider class="hl-724" />
                  <v-row class="pt-5">
                    <v-col
                      cols="6"
                      mb="6"
                      class="d-flex flex-column"
                    >
                      <h4 class="dark--text font-weight-medium fz-16">Current balance</h4>
                      <p class="mb-0" style="color: #637381">${{ balance }}</p>
                    </v-col>
                    <v-col
                      cols="6"
                      mb="6"
                      class="d-flex align-center justify-end"
                    >
                      <v-btn
                        class="text-none"
                        color="primary"
                        elevation="0"
                        @click="addBalanceDialog = true"
                        >Add Balance
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="cardList.length"
                  cols="12"
                  md="12"
                >
                  <v-divider class="hl-724" />
                  <v-row class="pt-5 pb-0">
                    <v-col
                      cols="6"
                      mb="6"
                      class="d-flex flex-column justify-center"
                    >
                      <h4 class="dark--text font-weight-medium fz-16">Auto-recharge</h4>
                      <p v-if="autoTopUp" class="mb-0 fz-12" style="color: #637381">When balance falls bellow ${{ balanceThreshole }}.</p>
                    </v-col>
                    <v-col
                      cols="6"
                      mb="6"
                      class="d-flex align-center justify-end"
                    >
                      <v-btn
                        color="primary"
                        class="px-5 py-5 text-none"
                        elevation="0"
                        @click="autoChargeDialog = true"
                        >{{ !autoTopUp ? 'Enable' : 'Disable' }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </template>
            </v-row>
          </v-card>
        </v-col>

        <v-col
          v-if="isDesktop"
          cols="12"
          md="12"
          class="px-0"
        >
          <v-divider></v-divider>
        </v-col>

        <v-col
          cols="12"
          md="12"
          v-if="Role.CompanyAdmin"
        >
          <v-row>
            <v-col
              cols="12"
              md="12"
              class="pt-5"
            >
              <v-row
                class="d-flex justify-md-end"
              >
                <v-col
                  :class="{ white: !isDesktop }"
                  cols="12"
                  md="3"
                  class="px-4 px-md-0"
                >
                  <h2 :class="isDesktop ? 'font-weight-semi-bold' : 'font-weight-medium'" class="fz-16">Account settings</h2>
                  <p v-if="isDesktop" class="fz-14 mt-2" style="color: #637381;">Manage your account information.</p>
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                  class="px-0 pt-0 d-flex justify-md-end align-start"
                >
                  <v-card
                    :class="isDesktop ? 'max-width-desktop' : 'max-width-mobile'"
                    width="100%"
                    :loading="settingsLoading"
                  >
                    <v-form
                      class="d-flex"
                    >
                      <div class="pa-2 w-100">
                        <v-col
                          cols="12"
                          md="12"
                          class="fz-14 py-0 d-flex align-center flex-column flex-md-row"
                        >
                          <v-list-item-avatar
                            :style="isDesktop ? '' : 'border:1px solid #7777'"
                            :width="isDesktop ? '100px' : '100%'"
                            :height="isDesktop ? '60px' : '94px'"
                            class="mr-md-3 mr-0 br-3"
                          >
                            <v-img
                              :alt="`${form.firstname} avatar`"
                              :src="form.link"
                            ></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content class="d-block">
                            <v-list-item-title
                              :class="isDesktop ? 'fz-14' : 'fz-16'"
                              :style="isDesktop ? '' : 'color:#6170C5'"
                              class="mb-3"
                              @click="() => $refs.fileImput.onClickFile()"
                            >
                              Change picture
                            </v-list-item-title>
                            <FileInput ref="fileImput" class="d-none d-md-flex" :filesUploaded.sync="filesUploaded" />
                          </v-list-item-content>
                        </v-col>

                        <v-col
                          cols="12"
                          md="12"
                          class="px-3"
                        >
                          <label class="fz-14 mb-1 d-block">Company Name</label>
                          <v-text-field
                            v-model="form.accountName"
                            :style="{ height: isDesktop ? '36px' : '48px' }"
                            :maxlength="50"
                            solo
                            outlined
                            label="Company Name"
                            type="text"
                            dense
                            hide-details
                            @keypress="onlyAlphanumeric"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                        >
                        <label class="fz-14 mb-1 d-block">Timezone</label>
                          <v-select
                            v-model="selectedTimeZone"
                            :items="timeZones"
                            :height="isDesktop ? '36px' : '48px'"
                            class="pa-0 cursor-pointer"
                            menu-props="offsetY"
                            hide-details
                            label="Select"
                            solo
                            outlined
                            dense
                          >
                            <template v-slot:append>
                              <v-icon size="15px">unfold_more</v-icon>
                            </template>
                          </v-select>
                        </v-col>
                        <!-- Company slug should be removed base on latest info -->
                        <!-- <v-col
                          cols="12"
                          md="12"
                        >
                          <label class="fz-14 mb-1  d-block">Company Slug</label>
                          <v-text-field
                            solo
                            outlined
                            label="Company Slug"
                            type="text"
                            v-model="form.companySlug"
                            dense
                            hide-details
                            disabled
                          ></v-text-field>
                        </v-col> -->
                        <v-col
                          cols="12"
                          md="12"
                          class="text-right"
                        >
                          <v-btn
                            :disabled="!accDataChanged || !form.accountName.trim()"
                            class="violet-button"
                            :class="isDesktop ? '' : 'w-100'"
                            :height="isDesktop ? '' : '48px'"
                            @click="updateAccount"
                          >
                            Save
                          </v-btn>
                        </v-col>
                      </div>
                    </v-form>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              v-if="isDesktop"
              cols="12"
              md="12"
              class="px-0"
            >
              <v-divider></v-divider>
            </v-col>

            <v-col
              :class="{ white: !isDesktop }"
              cols="12"
              md="3"
              class="px-4 px-md-0 mt-3 mb-3 mb-md-0 py-4 py-md-0"
            >
              <h2 :class="isDesktop ? 'font-weight-semi-bold' : 'font-weight-medium'" class="fz-16">Notify members</h2>
              <p v-if="isDesktop" class="fz-14 mt-2" style="color: #637381;">Send update notifications to members.</p>
            </v-col>

            <v-col
              cols="12"
              md="9"
              class="px-0 pt-0"
            >
              <v-row class="ma-0">
                <v-col
                  cols="12"
                  md="12"
                  class="d-flex justify-md-end align-start py-0 py-md-4 pa-0"
                >
                  <v-card
                    class="pa-5"
                    :class="isDesktop ? 'max-width-desktop' : ''"
                    width="100%"
                  >
                    <v-row>
                      <v-col
                        :class="{ 'grey--text pr-5' : !isDesktop }"
                        cols="9"
                        md="9"
                        class="fz-14"
                      >
                        Members <span class="font-weight-semi-bold">do not</span> receive notifications when new numbers
                        are added to your company.
                      </v-col>

                      <v-col
                        cols="3"
                        md="3"
                        class="d-flex align-center justify-end"
                      >
                        <v-btn
                          class="violet-button"
                         :height="isDesktop ? '' : '48px'"
                         :width="isDesktop ? '' : '88px'"
                          @click="updateStatus('addedNumberNotification')"
                        >
                          {{ !notifications.addedNumberNotification ? 'Enable' : 'Disable' }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

               <v-col
                 cols="12"
                 md="12"
                 class="d-flex justify-md-end align-start py-0 py-md-4 pa-0"
               >
                 <v-card
                   class="pa-5"
                   :class="isDesktop ? 'max-width-desktop' : ''"
                   width="100%"
                 >
                  <v-row>
                    <v-col
                      :class="{ 'grey--text pr-5' : !isDesktop }"
                       cols="9"
                       md="9"
                       class="fz-14"
                     >
                       Members <span class="font-weight-semi-bold">do not</span> receive notifications when you
                       remove number from your company.
                     </v-col>

                     <v-col
                       cols="3"
                       md="3"
                       class="d-flex align-center justify-end"
                     >
                       <v-btn
                         class="violet-button"
                         :height="isDesktop ? '' : '48px'"
                         :width="isDesktop ? '' : '88px'"
                         @click="updateStatus('removedNumberNotification')"
                       >
                         {{ !notifications.removedNumberNotification ? 'Enable' : 'Disable' }}
                       </v-btn>
                     </v-col>
                   </v-row>
                 </v-card>
               </v-col>
               <!-- <v-col
                 cols="12"
                 md="12"
                 class="d-flex justify-md-end align-start"
               >
                 <v-card
                   class="pa-5"
                   :class="isDesktop ? 'max-width-desktop' : 'max-width-mobile'"
                   width="100%"
                 >
                   <v-row>
                     <v-col
                       cols="12"
                       md="9"
                       class="fz-14"
                     >
                       Members <span class="font-weight-semi-bold">do not</span> receive notifications when new members
                        are added to your company.
                     </v-col>

                     <v-col
                       cols="12"
                       md="3"
                       class="d-flex align-center justify-end"
                     >
                       <v-btn
                         class="violet-button"
                         @click="updateStatus('addedMemberNotification')"
                       >
                         {{ !notifications.addedMemberNotification ? 'Enable' : 'Disable' }}
                       </v-btn>
                     </v-col>
                   </v-row>
                 </v-card>
               </v-col>
               <v-col
                 cols="12"
                 md="12"
                 class="d-flex justify-md-end align-start"
               >
                 <v-card
                   class="pa-5"
                   :class="isDesktop ? 'max-width-desktop' : 'max-width-mobile'"
                   width="100%"
                 >
                   <v-row>
                     <v-col
                       cols="12"
                       md="9"
                       class="fz-14"
                     >
                       Members <span class="font-weight-semi-bold">do not</span> receive notifications when you
                       remove member from your company.
                     </v-col>

                     <v-col
                       cols="12"
                       md="3"
                       class="d-flex align-center justify-end"
                     >
                       <v-btn
                         class="violet-button"
                         @click="updateStatus('removedMemberNotification')"
                       >
                         {{ !notifications.removedMemberNotification ? 'Enable' : 'Disable' }}
                       </v-btn>
                     </v-col>
                   </v-row>
                 </v-card>
               </v-col> -->
              </v-row>
            </v-col>

            <v-col
              cols="12"
              md="12"
              class="px-0"
            >
            </v-col>

            <!-- <v-col
              cols="12"
              md="3"
              class="px-4 px-md-0 mt-3"
            >
              <h2 class="fz-16 font-weight-semi-bold">Incoming calls & SMS</h2>
              <p class="fz-14 mt-2" style="color: #637381;">Choose how to handle incoming calls and SMS</p>
            </v-col>

            <v-col
              cols="12"
              md="9"
              class="px-0 d-flex justify-md-end align-start"
            >
              <v-card
                class="pa-5"
                :class="isDesktop ? 'max-width-desktop' : 'max-width-mobile'"
                width="100%"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-radio-group
                      v-model="simCard"
                      class="round-radio--button ma-0"
                      hide-details
                    >
                      <v-radio
                        v-for="sim in simGroup"
                        :key="sim"
                        :label="sim"
                        :value="sim"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-col> -->
            <v-col
                v-if="isDesktop && showSms"
                cols="12"
                class="px-0"
            >
              <v-divider></v-divider>
            </v-col>

<!--            TODO: Hide for a while-->
<!--            <v-col-->
<!--                v-if="showSms"-->
<!--                :class="{ white: !isDesktop }"-->
<!--                cols="12"-->
<!--                md="3"-->
<!--                class="px-4 px-md-0 mt-3 mb-3 mb-md-0 py-4 py-md-0"-->
<!--            >-->
<!--              <h2 :class="isDesktop ? 'font-weight-semi-bold' : 'font-weight-medium'" class="fz-16">Enable SMS</h2>-->
<!--              <p v-if="isDesktop" class="fz-14 mt-2" style="color: #637381;"></p>-->
<!--            </v-col>-->
            <!--            TODO: Hide for a while-->
<!--            <v-col-->
<!--                v-if="showSms"-->
<!--                cols="12"-->
<!--                md="9"-->
<!--                class="px-0 pt-0"-->
<!--            >-->
<!--              <v-row class="ma-0">-->
<!--                <v-col-->
<!--                    cols="12"-->
<!--                    md="12"-->
<!--                    class="d-flex justify-md-end align-start py-0 py-md-4 pa-0"-->
<!--                >-->
<!--                  <v-card-->
<!--                      class="pa-5"-->
<!--                      :class="isDesktop ? 'max-width-desktop' : ''"-->
<!--                      width="100%"-->
<!--                  >-->
<!--                    <v-row>-->
<!--                      <v-col-->
<!--                          :class="{ 'grey&#45;&#45;text pr-5' : !isDesktop }"-->
<!--                          style="text-align: justify"-->
<!--                          cols="9"-->
<!--                          md="9"-->
<!--                          class="fz-14"-->
<!--                      >-->
<!--                        Members can send SMS using the phone number purchased as the sender ID.-->
<!--                      </v-col>-->

<!--                      <v-col-->
<!--                          cols="3"-->
<!--                          md="3"-->
<!--                          class="d-flex align-center justify-end"-->
<!--                      >-->
<!--                        <v-btn-->
<!--                            :loading="sms.loading"-->
<!--                            class="violet-button"-->
<!--                            :height="isDesktop ? '' : '48px'"-->
<!--                            :width="isDesktop ? '' : '88px'"-->
<!--                            @click="openSmsBlock"-->
<!--                        >-->
<!--                          {{ campaignIsExist ? 'Edit' : 'Enable' }}-->
<!--                        </v-btn>-->
<!--                      </v-col>-->
<!--                    </v-row>-->
<!--                  </v-card>-->
<!--                </v-col>-->
<!--              </v-row>-->

<!--              <v-row class="ma-0">-->
<!--                <v-col-->
<!--                    cols="12"-->
<!--                    md="12"-->
<!--                    class="d-flex justify-md-end align-start py-0 py-md-4 pa-0"-->
<!--                >-->
<!--                  <SettingsCampaignForm v-if="sms.show"-->
<!--                                        @ok="getPaymentLink"-->
<!--                                        @cancel="sms.show = false"-->
<!--                                        :loading="sms.loading"-->
<!--                                        :brand="!!brandId"-->
<!--                                        :class="isDesktop ? 'max-width-desktop' : ''" />-->
<!--                </v-col>-->
<!--              </v-row>-->

<!--            </v-col>-->

          </v-row>
        </v-col>
        <v-col
          v-if="isDesktop"
          cols="12"
          md="12"
          class="px-0"
        >
          <v-divider></v-divider>
        </v-col>
        <v-col
          cols="12"
          md="12"
          class="py-6"
        >
          <v-row>
            <v-col
              :class="{ white: !isDesktop }"
              cols="12"
              md="3"
              class="px-4 px-md-0"
            >
              <h2 :class="isDesktop ? 'font-weight-semi-bold' : 'font-weight-medium'" class="fz-16">Deactivate account</h2>
            </v-col>
            <!-- Hide this block for NOW -- FONIO-1192 -->
            <!-- Close Account" Button missing in settings page -- FONIO-1708 -->
            <v-col
              cols="12"
              md="9"
              class="px-0 py-0 d-flex justify-md-end align-start"
            >
              <v-card
                class="pa-5"
                :class="isDesktop ? 'max-width-desktop' : 'max-width-mobile'"
                width="100%"
              >
                <v-row>
                  <v-col
                    v-if="isDesktop"
                    cols="12"
                    md="6"
                    class="fz-14 d-flex align-center"
                  >
                    Close this account
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="text-right"
                  >
                    <v-btn
                      :class="{ 'w-100': !isDesktop }"
                      class="violet-button"
                      @click="closeDialog = true"
                    >
                      Close account
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      persistent
      v-model="closeDialog"
      :max-width="isDesktop ? '350' : '260'"
    >
      <v-card>
        <v-card-title class="font-weight-bold">
          Close account
        </v-card-title>

        <v-card-text>
          Are you sure you want to close account?
        </v-card-text>

        <v-card-actions class="pb-5 pt-0 justify-md-end">

          <div :style="isDesktop ? '' : 'width: 50%'"
              class="mx-2">
            <v-btn
              color="secondary"
              outlined
              @click="closeDialog = false"
              class="w-100 text-capitalize"
            >
              Cancel
            </v-btn>
          </div>

          <div :style="isDesktop ? '' : 'width: 50%'"
              class="mx-2">
            <v-btn
              depressed
              color="primary"
              class="w-100 text-capitalize"
              @click="closeAccount"
            >
              Yes
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="removeCardDialog"
      :max-width="isDesktop ? '350' : '260'"
    >
      <v-card>
        <v-card-title class="font-weight-bold">
          Delete card
        </v-card-title>

        <v-card-text>
          Are you sure you want to delete card?
        </v-card-text>

        <v-card-actions class="pb-5 pt-0 justify-md-end">

          <div :style="isDesktop ? '' : 'width: 50%'"
              class="mx-2">
            <v-btn
              color="secondary"
              outlined
              @click="closeRemoveDialog"
              class="w-100 text-capitalize"
            >
              Cancel
            </v-btn>
          </div>

          <div :style="isDesktop ? '' : 'width: 50%'"
              class="mx-2">
            <v-btn
              depressed
              color="primary"
              class="w-100 text-capitalize"
              @click="removeCard"
            >
              Delete
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
        v-model="sms.modal.show"
        max-width="500"
    >
      <v-card>
        <v-card-title class="font-weight-bold">
          Cost
        </v-card-title>

        <v-card-text>
          <div class="black-text d-flex w-100" style="gap: 12px; flex-direction: column">
            <div class="d-flex align-center justify-md-space-between" v-if="!brandId">
              <div>Brand Creation</div>
              <div>${{ sms.modal.data.brand_setup_fee }}</div>
            </div>
            <div class="d-flex align-center justify-md-space-between" v-if="!brandId">
              <div>TCR Brand Vetting</div>
              <div>${{ sms.modal.data.trc_vetting_fee }}/brand</div>
            </div>
            <div class="d-flex align-center justify-md-space-between">
              <div>DCA Campaign Vetting</div>
              <div>${{ sms.modal.data.dca_vetting_fee }}</div>
            </div>
            <div class="d-flex align-center justify-md-space-between">
              <div>Monthly Campaign Fee</div>
              <div>${{sms.modal.data.campaign_fee}}/campaign</div>
            </div>
            <div class="d-flex align-center justify-md-space-between font-weight-bold">
              <div>Total</div>
              <div>${{sms.modal.data.amount}}</div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="pb-5 pt-0 justify-center">

          <div :style="isDesktop ? '' : 'width: 50%'"
               class="mx-2">
            <v-btn
                depressed
                color="primary"
                class="w-100 text-capitalize"
                @click="openSmsPaymentLink"
            >
              Pay
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Credit Dialog-->
    <v-dialog v-model="addBalanceDialog" width="600">
      <v-card max-height="276px">
        <v-card-title class="font-weight-bold">
          Add Balance
          <v-spacer></v-spacer>
          <v-btn @click="closeBalanceDialog" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <!-- <v-card-text class="fz-12 pb-0">
          Use credit to make paid calls with Freefone Voice.
        </v-card-text> -->

        <v-card-text class="pb-1">
          <p>Balance</p>
          <v-text-field
            v-model="balanceAmount"
            :style="{ height: isDesktop ? '36px' : '48px' }"
            :maxlength="5"
            solo
            outlined
            label=""
            type="text"
            dense
            hide-details
            @keypress="IsNumber"
          ></v-text-field>
        </v-card-text>
        <v-card-text v-if="balance" class="fz-12 pb-0">
          Your current balance is $ {{ balance }}.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5 text-none"
            elevation="0"
            outlined
            @click="closeBalanceDialog"
            >Cancel
          </v-btn>
          <v-btn
            :disabled="!balanceAmount"
            small
            color="primary"
            class="px-5 py-5 text-none"
            elevation="0"
            @click="addBalance"
            >Add Balance
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Auto charge dialog-->
    <v-dialog v-model="autoChargeDialog" width="600">
      <v-card max-height="276px">
        <v-card-title class="font-weight-bold">
          {{ !autoTopUp ? 'Enable' : 'Disable' }} Auto Recharge
          <v-spacer></v-spacer>
          <v-btn @click="closeAutoChargeDialog" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <!-- <v-card-text class="fz-12 pb-0">
          Use credit to make paid calls with Freefone Voice.
        </v-card-text> -->

        <v-card-text v-if="!autoTopUp" class="pb-1">
          <p>Balance Threshole</p>
          <v-text-field
            v-model="dialogBalanceThreshole"
            :style="{ height: isDesktop ? '36px' : '48px' }"
            :maxlength="3"
            solo
            outlined
            label=""
            type="text"
            dense
            hide-details
            @keypress="IsNumber"
          ></v-text-field>
        </v-card-text>
        <v-card-text v-else class="pb-1">
          Disable Auto Recharge?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5 text-none"
            elevation="0"
            outlined
            @click="closeAutoChargeDialog"
            >Cancel
          </v-btn>
          <v-btn
            :disabled="!autoTopUp && dialogBalanceThreshole <= 0"
            small
            color="primary"
            class="px-5 py-5 text-none"
            elevation="0"
            @click="toggleAutoRecharge"
            >{{ !autoTopUp ? 'Enable' : 'Disable' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <vue-snotify/>
  </div>
</template>

<script>
import FileInput from '@/components/FileInput'
// import SettingsCampaignForm from '../components/settings/SettingsCampaignForm.vue'

import { mapGetters, mapActions } from 'vuex'
import { Role } from '@/_helpers/role'

export default {
  name: 'Settings',
  components: { FileInput },
  data: () => ({
    Role,
    showSms: false, // TODO waiting for API
    notifications: {
      addedNumberNotification: false,
      removedNumberNotification: false,
      addedMemberNotification: false,
      removedMemberNotification: false
    },
    sum: '',
    date: '',
    settingsLoading: false,
    filesUploaded: [],
    form: {},
    simGroup: ['First SIM', 'No SIM', 'Last SIM'],
    simCard: 'First SIM',
    timeZones: [
      '-12:00',
      '-11:00',
      '-10:00',
      '-09:00',
      '-08:00',
      '-07:00',
      '-06:00',
      '-05:00',
      '-04:30',
      '-04:00',
      '-03:30',
      '-03:00',
      '-02:00',
      '-01:00',
      '-01:00',
      '+00:00',
      '+01:00',
      '+02:00',
      '+03:00',
      '+04:00',
      '+04:30',
      '+05:00',
      '+05:30',
      '+05:45',
      '+06:00',
      '+06:30',
      '+07:00',
      '+08:00',
      '+09:00',
      '+09:30',
      '+10:00',
      '+11:00',
      '+12:00',
      '+13:00'
    ],
    selectedTimeZone: '',
    closeDialog: false,
    // hasPaymentCard: true,
    removeCardDialog: false,
    removeId: '',
    sms: {
      campaign: [],
      loading: false,
      show: false,
      modal: {
        data: {},
        show: false,
        loading: false
      }
    },
    subdomain: '',
    accDataChanged: false,
    balance: 0,
    addBalanceDialog: false,
    balanceAmount: '',
    autoTopUp: false,
    autoChargeDialog: false,
    balanceThreshole: 0,
    dialogBalanceThreshole: ''
  }),
  computed: {
    ...mapGetters({
      userData: 'currentUser',
      companyData: 'currentCompany',
      userImage: 'userImage',
      cardList: 'paymentCards'
    }),
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    campaign () {
      return this.sms.campaign.length !== 0 ? this.sms.campaign[0] : {}
    },
    campaignIsExist () {
      return this.sms.campaign.length !== 0
    },
    brandId () {
      return this.$store.state.auth.userAccount.brand_id
    },
    domain () {
      return '.fonio.app'
    },
    hasLastPayment () {
      return !!this.sum || !!this.date
    }
  },
  async created () {
    await this.checkQuery()
    this.getData()
    this.getLastPayment()
    // this.loadPaymentMethods()
    this.loadSmsCampaign()
    this.getPaymentMethods()
  },
  watch: {
    filesUploaded (val) {
      console.log('val', val)
      this.form.link = URL.createObjectURL(val[0])
      this.uploadAvatar(val[0])
    },
    'sms.modal.show' (val) {
      if (!val) {
        this.closeModalSmsPayment()
      }
    },
    'form.accountName' (newVal, oldVal) {
      if (!oldVal) return
      this.accDataChanged = true
    },
    selectedTimeZone (newVal, oldVal) {
      if (!oldVal) return
      this.accDataChanged = true
    }
  },
  methods: {
    ...mapActions([
      'getAccountData',
      'getAccountImage',
      'deleteAccount',
      'logout',
      'getPaymentsHistory',
      'getPaymentMethods',
      'deletePaymentCard',
      'getSmsCampaign',
      'createCampaign',
      'postSubdomain',
      'isSubdomainAvailable',
      'addAccountBalance',
      'postPaymentCard',
      'patchPaymentCard',
      'setDefaultPaymentCard',
      'setAutoChargeBalance'
    ]),
    openSmsPaymentLink () {
      const url = this.sms.modal.data.url
      if (!url) return
      window.open(url, '_self')
    },
    openSmsBlock () {
      this.sms.show = !this.sms.show
    },
    closeModalSmsPayment () {
      this.sms.modal.show = false
      this.sms.modal.data = {}
    },
    async getPaymentLink (form) {
      try {
        this.sms.loading = true
        const payload = {
          campaign: {
            autoRenewal: form.autoRenewal,
            description: 'Default FONIO description',
            messageFlow: form.messageFlow,
            sample1: 'Default sample',
            sms_brand: form.sms_brand,
            usecase: form.usecase
          }
        }
        if (!this.brandId) {
          payload.brand = {
            firstName: this.$store.state.auth.currentUser.firstName,
            lastName: this.$store.state.auth.currentUser.lastName,
            ein: form.ein.toString(),
            companyName: form.companyName,
            displayName: form.companyName,
            country: form.country,
            state: form.state,
            postalCode: form.postalCode.toString(),
            city: form.city,
            street: form.street,
            email: form.email,
            mobilePhone: +form.phone,
            phone: +form.phone,
            website: form.website
          }
        }
        const { data } = await this.createCampaign(payload)
        this.sms.modal.data = data
        this.sms.modal.show = true
      } catch (e) {
        this.$snotify.notifications = []
        this.$snotify.error(e.message)
        throw new Error(e)
      } finally {
        this.sms.loading = false
      }
    },
    async loadSmsCampaign () {
      try {
        this.sms.loading = true
        const { data } = await this.getSmsCampaign()
        this.sms.campaign = data.data
      } catch (e) {
        this.$snotify.notifications = []
        this.$snotify.error(e.message)
        throw new Error(e)
      } finally {
        this.sms.loading = false
      }
    },
    async updateStatus (type) {
      try {
        /* type === 'add'
          ? this.isEnableAdd = !this.isEnableAdd
          : this.isEnableRemove = !this.isEnableRemove */
        this.notifications[type] = !this.notifications[type]
        await this.$store.dispatch('updateNotificationStatus', {
          ...this.notifications
        })

        this.$snotify.notifications = []
        this.$snotify.success('Successful')
      } catch (e) {
        throw new Error(e)
      }
    },
    async updateAccount () {
      try {
        await this.$store.dispatch('editUser', {
          accountName: this.form.accountName,
          timezone: this.selectedTimeZone
        })

        this.$snotify.notifications = []
        this.$snotify.success('Successfully updated')
      } catch (e) {
        this.$snotify.notifications = []
        this.$snotify.error(e.message)
        throw new Error(e)
      }
    },
    async getData () {
      try {
        this.settingsLoading = true

        const { data } = await this.getAccountData(this.$store.state.auth.currentUser.role)
        try {
          await this.getAccountImage({ responseType: 'arraybuffer' })
        } catch (error) {
          console.log(error)
        }
        this.form = {
          ...data,
          link: this.userImage
        }

        if (data.timezone) {
          this.selectedTimeZone = data.timezone
        } else {
          const offset = new Date().getTimezoneOffset()
          const o = Math.abs(offset)
          this.selectedTimeZone = (offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + (o % 60)).slice(-2)
        }

        this.subdomain = data.web_url
        this.balance = data.balance
        this.autoTopUp = data.auto_top_up
        this.balanceThreshole = data.balance_threshole

        if (!data.notifications || !data.notifications.length) return
        data.notifications.forEach(element => {
          switch (element) {
            case 'added_number_notification':
              this.notifications.addedNumberNotification = true
              break
            case 'removed_number_notification':
              this.notifications.removedNumberNotification = true
              break
            case 'added_member_notification':
              this.notifications.addedMemberNotification = true
              break
            case 'removed_member_notification':
              this.notifications.removedMemberNotification = true
              break
          }
        })
      } catch (e) {
        this.$snotify.notifications = []
        this.$snotify.error(e.message)
        throw new Error(e)
      } finally {
        this.settingsLoading = false
        setTimeout(() => {
          this.accDataChanged = false
        }, 200)
      }
    },
    /* async loadPaymentMethods () {
      try {
        await this.getPaymentMethods()
        if (!this.cardList.length) this.hasPaymentCard = false
      } catch (err) {
        this.hasPaymentCard = false
        throw new Error(err)
      }
    }, */
    async getLastPayment () {
      try {
        const params = {
          startDate: '2001-01-01',
          endDate: new Date().toISOString().split('T')[0],
          orderBy: 'payOn',
          limit: 1
        }
        const { data } = await this.getPaymentsHistory(params)
        if (!data || !data.list || !data.list.length) return
        if (data.list[0].stripeTransaction) {
          this.date = this.formatDate(data.list[0].stripeTransaction.paidOn)
          this.sum = parseFloat(data.list[0].stripeTransaction.amount).toFixed(2)
        } else {
          this.date = this.formatDate(data.list[0].payment.payOn)
          this.sum = parseFloat(data.list[0].payment.amount).toFixed(2)
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    uploadAvatar (file) {
      const formData = new FormData()
      formData.append('image', file)
      this.$store
        .dispatch('uploadUserAvatar', formData)
        .then((res) => {
          this.$snotify.notifications = []
          this.$snotify.success('Successful')
        }).catch(err => {
          this.$snotify.notifications = []
          this.$snotify.error(err.message)
          throw new Error(err)
        })
    },
    openRemoveDialog (id) {
      this.removeId = id
      this.removeCardDialog = true
    },
    closeRemoveDialog () {
      this.removeId = ''
      this.removeCardDialog = false
    },
    async removeCard () {
      try {
        if (!this.removeId) return
        const { data } = await this.deletePaymentCard(this.removeId)
        if (!data) return

        this.getPaymentMethods()
        this.$snotify.notifications = []
        this.$snotify.success('Payment card deleted.')
        this.closeRemoveDialog()
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.message)
        throw new Error(err)
      }
    },
    async checkQuery () {
      try {
        if (!this.$route.query.deletePm) return
        await this.deletePaymentCard(this.$route.query.deletePm)
        this.$router.replace({ query: null })
      } catch (err) {
        console.log(err)
      }
    },
    securityCard (card) {
      return card.replace(/^(.{4})([0-9]{8})/, '$1 **** **** ')
    },
    async closeAccount () {
      try {
        const { data } = await this.deleteAccount()
        if (!data) return
        this.closeDialog = false
        this.$snotify.notifications = []
        this.$snotify.success('Account closed succesfully')
        setTimeout(() => {
          this.logout()
          this.$router.push({ path: '/login' })
        }, 3000)
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.message)
        throw new Error(err)
      }
    },
    async saveSubdomain () {
      this.$snotify.notifications = []
      try {
        if (!this.subdomain) return
        const isSlugAvailable = await this.checkSlug()

        if (!isSlugAvailable) return this.$snotify.error('This subdomain has been taken. Please enter another one.')

        const { data } = await this.postSubdomain(this.subdomain)
        if (!data) return
        this.$snotify.success('Subdomain saved succesfully')
        this.getData()
      } catch (err) {
        this.$snotify.error(err.message)
        throw new Error(err)
      }
    },
    async checkSlug () {
      try {
        const { data } = await this.isSubdomainAvailable(this.subdomain)
        return data.isAvailable
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.message)
      }
    },
    async addPaymentMethod () {
      try {
        const { data } = await this.postPaymentCard()
        if (!data || !data.url) return
        window.location = data.url
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.message)
      }
    },
    async editPaymentMethod (id) {
      try {
        const { data } = await this.patchPaymentCard({ id })
        if (!data || !data.url) return
        window.location = data.url
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.message)
      }
    },
    async setDefault (id) {
      try {
        const { data } = await this.setDefaultPaymentCard(id)
        if (!data) return
        this.$snotify.success('Default card set succesfully')
        this.getPaymentMethods()
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.message)
      }
    },
    closeAutoChargeDialog () {
      this.autoChargeDialog = false
      this.dialogBalanceThreshole = ''
    },
    async toggleAutoRecharge () {
      try {
        const { data } = await this.setAutoChargeBalance({ enable: !this.autoTopUp, threshole: this.dialogBalanceThreshole })
        if (!data) return
        this.$snotify.success(`Auto Recharge ${this.autoTopUp ? 'disabled' : 'enabled'}`)
        this.closeAutoChargeDialog()
        this.getData()
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.message)
      }
    },
    closeBalanceDialog () {
      this.addBalanceDialog = false
      this.balanceAmount = ''
    },
    async addBalance () {
      this.$snotify.notifications = []
      try {
        const { data } = await this.addAccountBalance({ amount: +this.balanceAmount })
        if (!data || !data.url) return
        window.location = data.url
        this.closeBalanceDialog()
      } catch (err) {
        this.$snotify.error(err.message)
        throw new Error(err)
      }
    },
    onlyLetters (event) {
      if (!/^[A-Za-z\d]+$/.test(event.key)) return event.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-wrapper {
  max-width: 100%
}
.cursor-pointer::v-deep *{
  cursor: pointer;
}
.max-width-desktop {
  max-width: 514px;
}
.max-width-mobile {
  max-width: 100%;
}
.no-wrap {
  white-space: nowrap;
}

.subdomain-text {
  width: auto;
  height: 36px;
  &::v-deep input {
    color: #111 !important;
  }
}

.save-subdomain::v-deep.v-btn--disabled {
  background: grey !important;
  color: #ccc !important;
  border-color: grey;
}
</style>
